.synd-app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dashboard {
  padding: 20px;
}

.test {
  margin-top: 0px 0;
}

.dashboard-tab {
  padding: 20px 0;
}

.arrow-bg {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgb(169, 255, 166);
  margin-top: 2px;
}

.leads-container {
  display: flex;
  gap:30px;
}

.missing-data-container .r-container-wrapper {
  padding: 1.625rem;
}
.missing-data-container .r-container-wrapper raul-button,
.missing-data-container .r-container-wrapper .content-text {
  position: relative;
  display: inline-block;
}
.missing-data-container .r-container-wrapper raul-icon {
  position: relative;
  display: inline-block;
  font-size: 42px;
}
.missing-data-container .r-container-wrapper .content-text p {
  margin: 0 !important;
}
.missing-data-container .r-container-wrapper .r-button .r-icon {
  position: absolute;
  margin: 4px 0 0 0;
  font-size: 20px;
}
.missing-data-container .r-container-wrapper .r-button .r-button__content--icon-only {
  position: relative;
  border: none;
  margin-left: 15px;
}
.missing-data-container .r-container-wrapper .r-button.r-button--small {
  margin-top: 7px;
}
.missing-data-container .r-container-wrapper .r-button.r-button--default .r-button__content span {
  font-size: 12px;
  font-weight: bold;
}

.missing-data-container .aside-missing-data {
  position: relative;
  display: block;  
  overflow-y: auto;
  max-height: calc(100vh - 360px);
}
.missing-data-container .aside-missing-data raul-icon {
  position: relative;
  display: inline-block;
  font-size: 42px;
}
.missing-data-container .aside-missing-data .content-text {
  position: relative;
  display: inline-block;
}
.syndication-data .aside-missing-data {
  max-height: calc(100vh - 265px) !important;
}
.aside-missing-data-margin {
  margin-left: 45px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.dropdown {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 100%;
}
.print-only {
  display: none;
}
@media print {
  .print-only {
    display: block;
  }
}

.syndication-data .r-progress__remove-btn {
  display: none;
}

.r-tooltip {
  background-color:#fff;
  color: #000000;  
  z-index:10000;
}

.r-tooltip[x-placement^=top] .r-tooltip__arrow,.r-tooltip[x-placement^=bottom] .r-tooltip__arrow {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.two-column-list {
  -moz-column-count: 2;
  -moz-column-gap: 10px;
  -webkit-column-count: 2;
  -webkit-column-gap: 10px;
  column-count: 2;
  column-gap: 10px;
  list-style-type:disc;
}

.three-column-list {
  -moz-column-count: 3;
  -moz-column-gap: 10px;
  -webkit-column-count: 3;
  -webkit-column-gap: 10px;
  column-count: 3;
  column-gap: 10px;
  list-style-type:disc;
}
.channel-action-items raul-action .r-action__button{
  font-size: 14px;
  height: 21px;
  line-height: 21px;
}

#email-aside .email-aside-border {
  border-bottom: 1.5px solid #ebedee !important;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #0076cc!important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #fff;
}

raul-popover-label {
  max-height:250px !important;
  overflow-y : auto;
}

.even-odd:nth-child(odd) {
  background-color: #f7f8f9;
}
.font-24 {
  font-size: 24px;
}

.missing-email-alert raul-alert .r-alert  {
  font-size: 12px !important;
  padding: 0.5rem !important;
}

.raul-status {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
  font-size: .875rem;
}

.raul-status .r-status {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  background-color: #ebedee;
  font-size: .75rem;
  font-weight: 500;
  color: #37474f;
  text-align: left;
  line-height: 1.25;
  border-radius: .125rem;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  max-width: 100%;
}

.raul-status .r-status--success {
  background-color: #e7f3eb !important;
  color: #139c3e !important;
}

.raul-status .r-status--destructive {
  background-color: #fae8e9 !important;
  color: #d01a1f !important;
}

#missing-data-aside .r-aside,
#medium-aside .r-aside {
  z-index: 1998;
}