.app-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1999;
    display: block;
}

/* fixing RAUL spinner */
.app-loader .r-page-loader__spinner {
    border-style: solid;
}