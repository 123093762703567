.app-alert {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 60px;
    background-color: white;
    z-index: 999;
}
.dashboard .app-alert {
    padding: 0;
}